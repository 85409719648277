import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/stores/auth'
import utils from '@/utils/utils'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/LoginView.vue')
    },
    {
      path: '/recover',
      name: 'recover',
      component: () => import('@/views/RecoverView.vue')
    },
    {
      path: '/renew/:token',
      name: 'renew',
      component: () => import('@/views/RenewView.vue')
    },
    {
      path: '/selectorgan',
      name: 'selectorgan',
      component: () => import('@/views/SelectOrganView.vue')
    },
    {
      path: '/opendocs',
      name: 'opendocs',
      component: () => import('@/views/OpenDocsView.vue')
    },
    {
      path: '/home',
      name: 'home',
      meta: { auth: true },
      component: () => import('@/views/HomeView.vue')
    },
    {
      path: '/users',
      name: 'users',
      meta: { auth: true },
      component: () => import('../views/UsersView.vue')
    },
    {
      path: '/organs',
      name: 'organs',
      meta: { auth: true },
      component: () => import('../views/OrgansView.vue')
    },
    {
      path: '/units',
      name: 'units',
      meta: { auth: true },
      component: () => import('../views/UnitsView.vue')
    },
    {
      path: '/demandants',
      name: 'demandants',
      meta: { auth: true },
      component: () => import('../views/DemandantsView.vue')
    },
    {
      path: '/comissions',
      name: 'comissions',
      meta: { auth: true },
      component: () => import('../views/ComissionsView.vue')
    },
    {
      path: '/comissionmembers/:id(\\d+)',
      name: 'comissionmembers',
      meta: { auth: true },
      component: () => import('../views/ComissionMembers.vue')
    },
    {
      path: '/sectors',
      name: 'sectors',
      meta: { auth: true },
      component: () => import('../views/SectorsView.vue')
    },
    {
      path: '/ordinators',
      name: 'ordinators',
      meta: { auth: true },
      component: () => import('../views/OrdinatorsView.vue')
    },
    {
      path: '/programs',
      name: 'programs',
      meta: { auth: true },
      component: () => import('../views/ProgramsView.vue')
    },
    {
      path: '/dotations',
      name: 'dotations',
      meta: { auth: true },
      component: () => import('../views/DotationsView.vue')
    },
    {
      path: '/dfds',
      name: 'dfds',
      meta: { auth: true },
      component: () => import('../views/DfdsView.vue')
    },
    {
      path: '/etps',
      name: 'etps',
      meta: { auth: true },
      component: () => import('../views/EtpsView.vue')
    },
    {
      path: '/catalogs',
      name: 'catalogs',
      meta: { auth: true },
      component: () => import('../views/CatalogsView.vue')
    },
    {
      path: '/catalogitems/:id(\\d+)',
      name: 'catalogitems',
      meta: { auth: true },
      component: () => import('../views/CatalogItemsView.vue')
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      meta: { auth: true },
      component: () => import('../views/SuppliersView.vue')
    },
    {
      path: '/supplier-request/:organId(\\d+)',
      name: 'supplier_request',
      meta: { auth: false },
      component: () => import('../views/SupplierRequestView.vue')
    },
    {
      path: '/processes',
      name: 'processes',
      meta: { auth: true },
      component: () => import('../views/ProcessesView.vue')
    },
    {
      path: '/pricerecords',
      name: 'pricerecords',
      meta: { auth: true },
      component: () => import('../views/PriceRecordsView.vue')
    },
    {
      path: '/proposal',
      name: 'proposal',
      meta: { auth: true },
      component: () => import('../views/ProposalView.vue')
    },
    {
      path: '/proposal_supplier/:token',
      name: 'proposal_supplier',
      component: () => import('../views/ProposalSupplierView.vue')
    },
    {
      path: '/riskiness',
      name: 'riskiness',
      meta: { auth: true },
      component: () => import('../views/RiskMapsView.vue')
    },
    {
      path: '/refterms',
      name: 'refterms',
      meta: { auth: true },
      component: () => import('../views/RefTermsView.vue')
    },
    {
      path: '/pca',
      name: 'pca',
      meta: { auth: true },
      component: () => import('../views/PCAView.vue')
    },
    {
      path: '/trasmission',
      name: 'trasmission',
      meta: { auth: true },
      component: () => import('../views/TransmissionView.vue')
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('../views/ForbiddenView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notfound',
      component: () => import('../views/NotFoundView.vue')
    }
  ]
})

router.beforeEach(async (to) => {
  if (to.name != 'selectorgan' && to.meta?.auth) {
    utils.load(true)
    try {
      const isAuthenticated = await auth.is_authenticated(to.path)
      if (!isAuthenticated) {
        return '/'
      }
    } catch (e) {
      return e.response?.status === 403
        ? '/forbidden' : e.response?.status === 404
          ? '/notfound' : '/'
    } finally {
      utils.load(false)
    }
  }
})

export default router
